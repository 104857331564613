import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from '@emotion/styled';

import Section from '@components/Section';
import Image from '@components/Image';
import Icons from '@icons';
import mediaqueries from '@styles/media';
import { IAuthor } from '@types';

import { GridLayoutContext } from './Articles.List.Context';

const authorQuery = graphql`
  {
    site: allSite {
      edges {
        node {
          siteMetadata {
            hero {
              heading
              maxWidth
            }
          }
        }
      }
    }
  }
`;

const ArticlesHero: React.FC<IAuthor> = ({ authors }) => {
  const { gridLayout = 'tiles', hasSetGridLayout, setGridLayout } = useContext(
    GridLayoutContext,
  );

  const results = useStaticQuery(authorQuery);
  const hero = results.site.edges[0].node.siteMetadata.hero;
  const tilesIsActive = hasSetGridLayout && gridLayout === 'tiles';
  const featuredAuthor = authors.find(author => author.featured);

  if (!featuredAuthor) {
    throw new Error(`
      No featured Author found.
      Please ensure you have at least featured Author.
  `);
  }

  return (
    <Section relative id="Articles__Hero">
      <HeadingContainer >
        <div style={{ maxWidth: `${hero.maxWidth}px` }}>
        <HeroHeading><span style={{ display: "block", lineHeight: "2" }}>Hi there! 👋</span>I'm Yarin, a software engineer. Welcome to my website!</HeroHeading>
        <LearnMoreButton to="/about/">Learn more about me</LearnMoreButton>
        </div>
          <HeroImage>
              <ProfilePicture src={featuredAuthor.avatar.large} alt="Yarin Ronel's profile picture" />
          </HeroImage>
      </HeadingContainer>
      <SubheadingContainer>
        <GridControlsContainer>
          <GridButton
            onClick={() => setGridLayout('tiles')}
            active={tilesIsActive}
            data-a11y="false"
            title="Show articles in Tile grid"
            aria-label="Show articles in Tile grid"
          >
            <Icons.Tiles />
          </GridButton>
          <GridButton
            onClick={() => setGridLayout('rows')}
            active={!tilesIsActive}
            data-a11y="false"
            title="Show articles in Row grid"
            aria-label="Show articles in Row grid"
          >
            <Icons.Rows />
          </GridButton>
        </GridControlsContainer>
      </SubheadingContainer>
    </Section>
  );
};

export default ArticlesHero;

const SubheadingContainer = styled.div`
  display: flex;
  margin-bottom: 2em;

  ${mediaqueries.phablet`
    display: none;
  `};
`;

const GridControlsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  ${mediaqueries.tablet`
    display: none;
  `};
`;

const HeadingContainer = styled.div`
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  
  ${mediaqueries.phablet`
  margin: 50px 0;
  flex-direction: column;
  `};
`;


const HeroHeading = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  font-family: ${p => p.theme.fonts.sansSerif};
  line-height: 1.15;
  color: ${p => p.theme.colors.primary};

  a {
    color: ${p => p.theme.colors.accent};
  }

  ${mediaqueries.desktop`
    font-size: 38px
  `}

  ${mediaqueries.tablet`
    font-size: 32px;
  `}

  ${mediaqueries.phablet`
  font-size: 28px;
  `}
`;

const LearnMoreButton = styled(Link)`
  background-image: linear-gradient(to right, rgb(121, 0, 255) 0%, rgb(255, 88, 202)  51%, rgb(121, 0, 255)  100%);
  margin-top: 1em;
  
  padding: 1em 2em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  border-radius: 10px;
  display: table;
  border: 2px solid ${p => p.theme.colors.background};
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);


  &:hover, &:active {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }

  ${mediaqueries.phablet`
  margin: 1em auto;
  `};
`;

const HeroImage = styled.div`
width: 100%;
max-width: 500px;
margin-left: 1em;

${mediaqueries.phablet`
    width: 100%;
    max-width: 350px;
    margin-left: 0;
`};

  border-radius: 50%;
  border: 2px solid ${p => p.theme.colors.background};
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);
`;

const ProfilePicture = styled(Image)`
  border-radius: 50%;
`;

const GridButton = styled.button<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: transparent;
  transition: background 0.25s;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    background: ${p => p.theme.colors.hover};
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -10%;
    width: 120%;
    height: 120%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 50%;
  }

  svg {
    opacity: ${p => (p.active ? 1 : 0.25)};
    transition: opacity 0.2s;

    path {
      fill: ${p => p.theme.colors.primary};
    }
  }
`;
